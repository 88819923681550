input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-label-transform {
  transform: translate(20px, -43px);
}

.input-label-transform--top {
  transform: translate(15px, -53px);
}

.ant-input {
  padding: 26px 20px 6px;
  font-size: 17px;
}

.ant-input-password {
  padding: 26px 20px 6px;
}

.input-label {
  position: absolute;
  color: @color-french-gray;
  z-index: 1;
  top: 60px;
  left: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95%;
  text-align: left;
  transition: transform 0.3s ease, font-size 0.3s ease;
  pointer-events: none;
  font-size: 17px;
  &:extend(.input-label-transform);
  &__focus,
  &__not-empty {
    &:extend(.input-label-transform--top);
    pointer-events: none;
    font-size: 12px;
  }
}

.ant-input:focus ~ span.input-label {
  &:extend(.input-label-transform--top);
  pointer-events: none;
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 15px;

  @media (max-width: @screen-sm) {
    margin-bottom: 22px;
  }
}

.ant-form-item-has-success ~ .input-label,
.ant-form-item-has-error ~ .input-label {
  &:extend(.input-label-transform--top);
  pointer-events: none;
  font-size: 12px;
}

.ant-form-item-has-error ~ .input-label {
  color: #df150d;
}

.ant-form-item-has-feedback.ant-form-item-has-success
  span.ant-form-item-children-icon {
  width: 24px;
  height: 24px;
  right: 10px;
}

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: none;
}

.ant-form-item-control-input {
  .anticon-check-circle {
    display: inline-block;
    background-repeat: no-repeat;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.ant-input-password {
  padding: 26px 20px 6px;

  .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.ant-form-item-explain {
  padding-left: 21px;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 0px;
  position: absolute;
  min-height: 40px;
  bottom: -40px;

  &-error {
    color: #f5222d;
  }
}
